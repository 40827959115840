@import "hero";

main {
		background: #ffffff;
}

/* ScrollAnimationComponent.css */
.scroll-animation {
		opacity: 0;
		transform: translateY(100px);
		transition: opacity 2s ease-in-out, transform 2s ease-in-out;
}

.scroll-animation.visible {
		opacity: 1;
		transform: translateY(0);
}

.bg-hero-image {
				background-size: cover;
				background-repeat: no-repeat;
}

.webinar {
				background-position: 0px -100px;
}

@media (min-width: 2224px) {
				.webinar {
								background-position: 0px -290px;
				}
}

.contact.active {
				color: #ffffff !important;
}

.sticky-container {
				transition: all 0.3s ease-in-out;
}

.sticky {
				position: fixed;
				top: 50%;
				max-width: 1200px;
				z-index: 1000; /* Adjust the z-index as needed */
}

.sticky-content {
				padding: 20px;
}


.active {
				color: #349E5F !important;
}

.video-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh; /* This ensures the video takes up the full viewport height */
		overflow: hidden;
}

.video-element {
		width: 100%;
		height: 100%; /* Make the video element fill the video-container */
		object-fit: cover; /* Scale the video to cover the container while maintaining aspect ratio */
}

.play-button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 70px;
		height: 70px;

		cursor: pointer;
		transition: transform 0.2s;
}

.video-lightbox:hover .play-button {

}

.modal {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 1000;
		transition: opacity 0.3s ease-in-out;
		overflow: hidden;

		&.open {
				display: block;
		}

		.modal-overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
		}

		.modal-body {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100vh;
		}

		.modal-video {
				position: relative;
				width: 100%;
				max-width: 800px;
				background-color: #fff;
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
				border-radius: 10px;
				overflow: hidden;
		}
}