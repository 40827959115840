@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css */

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  background: #FFFFFF;
}


code {
  font-family: 'Open Sans', sans-serif;
}
